import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";


let Plan = () => {
    let baseUrl = 'https://118.139.167.49:5000';


    return (
        <>
            <section className="w90 contactus fcs-display m_auto">
                <div className="pricing" id="pricing" style={{marginTop: '-35px'}}>
                    <div className="head">
                        <h6>Increase your chances of matchmaking</h6>
                        <h3>Subscription Plans</h3>
                        <div className="spans" style={{marginTop: '10px'}}>
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <h2>₹999 <span><del>₹1499</del></span></h2>
                            <h5>1 Month Subscription </h5>
                            
                            <ul>
                                <li><span>25</span> Contact Us</li>
                                <li><span>50</span> Message</li>
                                <li><span className="span_x">x</span> Premium Badge</li>
                                <li><span className="span_x">x</span> Relationship Manager</li>
                                <li><span className="span_x">x</span> Nri Rishtey</li>
                                <li><span className="span_x">x</span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6></h6>
                        </div>
                        <div className="card">
                            <h2>₹1899 <span><del>₹2999</del></span></h2>
                            <h5>3 Month Subscription </h5>
                            <ul>
                                <li><span>80</span> Contact Us</li>
                                <li><span>160</span> Message</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Premium Badge</li>
                                <li><span className="span_x">x</span> Relationship Manager</li>
                                <li><span className="span_x">x</span> Nri Rishtey</li>
                                <li><span className="span_x">x</span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6>Popluar</h6>
                        </div>
                        <div className="card">
                            
                            <h2>₹2999 <span><del>₹4999</del></span></h2>
                            <h5>6 Month Subscription </h5>
                            <ul>
                                <li><span>170</span> Contact Us</li>
                                <li><span>340</span> Message</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Premium Badge</li>
                                <li><span className="span_x">x</span> Relationship Manager</li>
                                <li><span className="span_x">x</span> Nri Rishtey</li>
                                <li><span className="span_x">x</span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6></h6>
                        </div>
                        <div className="card">
                           
                            <h2>₹3899 <span><del>₹6999</del></span></h2>
                            <h5>9 Month Subscription </h5>
                            <ul>
                                <li><span>300</span> Contact Us</li>
                                <li><span>600</span> Message</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Premium Badge</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Relationship Manager</li>
                                <li><span className="span_x">x</span> Nri Rishtey</li>
                                <li><span className="span_x">x</span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6></h6>
                        </div>
                        <div className="card">
                           
                            <h2>₹4799 <span><del>₹8999</del></span></h2>
                            <h5>12 Month Subscription </h5>
                            <ul>
                                <li><span>450</span> Contact Us</li>
                                <li><span>900</span> Message</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Premium Badge</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Relationship Manager</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Nri Rishtey</li>
                                <li><span className="span_x">x</span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6>Top Seller</h6>
                        </div>
                        <div className="card">

                            <h2>₹7999 <span><del>₹14999</del></span></h2>
                            <h5>VIP Plan </h5>
                            <ul>
                                <li><span>700</span> Contact Us</li>
                                <li><span>1400</span> Message</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Premium Badge</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Relationship Manager</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> Nri Rishtey</li>
                                <li><span className="span_yes"><i className="bi bi-check-lg"></i></span> VIP Treatment</li>
                            </ul>
                            <button>Choose Plan</button>
                            <h6>Best Value</h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Plan;
