import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

let TodaysMatches = () =>
{
    const communityes = [
        "Brahma Kumaris",
        "Divya Jyoti Jagrati Sansthan",
        "Dera Sacha Sauda, Sirsa",
        "Guruji",
        "Jagat Guru Rampal Ji",
        "Manav Utthan Sewa Samiti",
        "Radha Saomi Satsang, Beas",
        "Radhasoami Satsang, Dayalbagh",
        "Radha Swami Satsang Dinod",
        "Sachkhand Nanak Dham, Loni",
        "Sadhguru",
        "Sant Asharamji Bapuji",
        "Sant Nirankari",
        "Sri Sri Ravi Shankar",
        "Sawan Kirpal Ruhani Mission",
        "Other"
    ];

    const [recently_joined, setRecentlyJoined] = useState('all'); // 'all' as the default selected option
    const [active_members, setActiveMembers] = useState('all'); // 'all' as the default selected option
    const [anaual_income, setAunalIncome] = useState('all'); // 'all' as the default selected option
    const [marital_status, setMaritalStatus] = useState('all'); // 'all' as the default selected option
    const [spritual_path, setSpritualPath] = useState('all'); // 'all' as the default selected option

    const handleOptionChange_rj = (event) => {
        setRecentlyJoined(event.target.value);
    };

    const handleOptionChange_am = (event) => {
        setActiveMembers(event.target.value);
    };

    const handleOptionChange_ai = (event) => {
        setAunalIncome(event.target.value);
    };

    const handleOptionChange_ms = (event) => {
        setMaritalStatus(event.target.value);
    };

    const handleOptionChange_sp = (event) => {
        setSpritualPath(event.target.value);
    };

    return (
        <>
            <section className="w70 new_matches fcs-display m_auto">
            <div className="match_left_bx">
                    <div className="filter">
                        <h3><i className="bi bi-filter-circle-fill"></i> Filter</h3>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Recently Joined</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={recently_joined === 'all'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinDay"
                                        checked={recently_joined === 'withinDay'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a day (152)</label>
                                </div>

                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinWeek"
                                        checked={recently_joined === 'withinWeek'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a week (500+)</label>

                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinMonth"
                                        checked={recently_joined === 'withinMonth'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a month (2000+)</label>
                                </div>
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Active Members</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={active_members === 'all'}
                                        onChange={handleOptionChange_am}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinaMonth"
                                        checked={active_members === 'withinaMonth'}
                                        onChange={handleOptionChange_am}
                                    />
                                    <label>Within a month (52)</label>
                                </div>
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Annual Income</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={anaual_income === 'all'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="0-1"
                                        checked={anaual_income === '0-1'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>Upto INR 1 Lakh (107)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="1-2"
                                        checked={anaual_income === '1-2'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 1 Lakh to 2 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="2-4"
                                        checked={anaual_income === '2-4'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 2 Lakh to 4 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="4-7"
                                        checked={anaual_income === '4-7'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 4 Lakh to 7 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="10-15"
                                        checked={anaual_income === '10-15'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 10 Lakh to 15 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="15-20"
                                        checked={anaual_income === '15-20'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 15 Lakh to 20 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="30-50"
                                        checked={anaual_income === '30-50'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>INR 30 Lakh to 50 Lakh (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="0k-40k"
                                        checked={anaual_income === '0k-40k'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>Upto USD 40K (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="40k-60k"
                                        checked={anaual_income === '40k-60k'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>USD 40K to 60K (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="60k-80k"
                                        checked={anaual_income === '60k-80k'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>USD 60K to 80K (41)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="350k-800k"
                                        checked={anaual_income === '350k-800k'}
                                        onChange={handleOptionChange_ai}
                                    />
                                    <label>USD 350K to 800K (41)</label>
                                </div>
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Marital Status</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={marital_status === 'all'}
                                        onChange={handleOptionChange_ms}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="nevermarried"
                                        checked={marital_status === 'nevermarried'}
                                        onChange={handleOptionChange_ms}
                                    />
                                    <label>Never Married (45)</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="widowed"
                                        checked={marital_status === 'widowed'}
                                        onChange={handleOptionChange_ms}
                                    />
                                    <label>Widowed (5)</label>
                                </div>
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Spirtual Path (community)</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={marital_status === 'all'}
                                        onChange={handleOptionChange_ms}
                                    />
                                    <label>All</label>
                                </div>
                                {
                                    communityes.map((el, i) => (
                                        <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el}
                                                checked={marital_status === el}
                                                onChange={handleOptionChange_ms}
                                            />
                                            <label>{el}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="match_right_bx">
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src="https://storage.ws.pho.to/s2/5ad62809094a4d352fcce884eb7e74fe952859df_m.png" alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>Jareena Sinha <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                <div className="r_fav">
                                <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Add Favorite</span>
                                <i className="bi bi-heart-fill"></i>
                                </div>
                            </div>
                            <ul className="fcs-display w-100">
                                <li>24 yrs, 5'3"</li>
                                <li>Never Married</li>
                                <li>Muslim, Sunni</li>
                                <li>Kolkata, West Bengal</li>
                                <li>Bengali</li>
                                <li>Not Specified</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt porro voluptas minus cupiditate, quos vitae. <Link to="#">Show more.</Link></p>
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display" style={{display:"none"}}>
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                            </div>
                            <div className="connected_profile w-100 fcc-display" >
                                <p><Link to='#'>Upgrade</Link>to
                                Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                <button><i className="bi bi-chat-right-dots-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src="https://storage.ws.pho.to/s2/5ad62809094a4d352fcce884eb7e74fe952859df_m.png" alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>Jareena Sinha <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                <div className="r_fav">
                                <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Add Favorite</span>
                                <i className="bi bi-heart-fill"></i>
                                </div>
                            </div>
                            <ul className="fcs-display w-100">
                                <li>24 yrs, 5'3"</li>
                                <li>Never Married</li>
                                <li>Muslim, Sunni</li>
                                <li>Kolkata, West Bengal</li>
                                <li>Bengali</li>
                                <li>Not Specified</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt porro voluptas minus cupiditate, quos vitae. <Link to="#">Show more.</Link></p>
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display">
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                            </div>
                            <div className="connected_profile" style={{display:"none"}}>
                                <p><Link to='#'>Upgrade</Link>to
                                Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-telephone-fill"></i> Whatsaap</button>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src="https://storage.ws.pho.to/s2/5ad62809094a4d352fcce884eb7e74fe952859df_m.png" alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>Jareena Sinha <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                <div className="r_fav">
                                <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Add Favorite</span>
                                <i className="bi bi-heart-fill"></i>
                                </div>
                            </div>
                            <ul className="fcs-display w-100">
                                <li>24 yrs, 5'3"</li>
                                <li>Never Married</li>
                                <li>Muslim, Sunni</li>
                                <li>Kolkata, West Bengal</li>
                                <li>Bengali</li>
                                <li>Not Specified</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt porro voluptas minus cupiditate, quos vitae. <Link to="#">Show more.</Link></p>
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display" >
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                            </div>
                            <div className="connected_profile" style={{display:"none"}}>
                                <p><Link to='#'>Upgrade</Link>to
                                Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-telephone-fill"></i> Whatsaap</button>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src="https://storage.ws.pho.to/s2/5ad62809094a4d352fcce884eb7e74fe952859df_m.png" alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>Jareena Sinha <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                <div className="r_fav">
                                <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Add Favorite</span>
                                <i className="bi bi-heart-fill"></i>
                                </div>
                            </div>
                            <ul className="fcs-display w-100">
                                <li>24 yrs, 5'3"</li>
                                <li>Never Married</li>
                                <li>Muslim, Sunni</li>
                                <li>Kolkata, West Bengal</li>
                                <li>Bengali</li>
                                <li>Not Specified</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt porro voluptas minus cupiditate, quos vitae. <Link to="#">Show more.</Link></p>
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display" style={{display:"none"}}>
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                            </div>
                            <div className="connected_profile w100 fcc-display" >
                                <p><Link to='#'>Upgrade</Link>to
                                Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-telephone-fill"></i> Whatsaap</button>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src="https://storage.ws.pho.to/s2/5ad62809094a4d352fcce884eb7e74fe952859df_m.png" alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>Jareena Sinha <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                <div className="r_fav">
                                <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Add Favorite</span>
                                <i className="bi bi-heart-fill"></i>
                                </div>
                            </div>
                            <ul className="fcs-display w-100">
                                <li>24 yrs, 5'3"</li>
                                <li>Never Married</li>
                                <li>Muslim, Sunni</li>
                                <li>Kolkata, West Bengal</li>
                                <li>Bengali</li>
                                <li>Not Specified</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt porro voluptas minus cupiditate, quos vitae. <Link to="#">Show more.</Link></p>
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display">
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                            </div>
                            <div className="connected_profile" style={{display:"none"}}>
                                <p><Link to='#'>Upgrade</Link>to
                                Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-telephone-fill"></i> Whatsaap</button>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TodaysMatches;