import React, { useEffect, useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../helpers/databaseHelpers";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import {
  fetchCountry,
  fetchStateMultiple,
  fetchCityMultiple,
  fetchReligion,
  fetchCommunity,
  fetchMaritalStatus,
  fetchMotherTongue,
} from "../../helpers/databaseHelpers";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#a01c22",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px #a01c1f78",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

const AirbnbThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
};

const convertHeight = (inputString) => {
  let parts = inputString.split("'");
  let feet = parseFloat(parts[0].trim());
  let inches = parseFloat(parts[1].replace('"', "").trim());
  let decimalValue = feet + "." + inches;
  return decimalValue;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Setting = () => {
  let baseUrl = 'https://118.139.167.49:5000';
  const [userDetails, setUserDetails] = useState([]);
  const [userEdit, setUserEdit] = useState(false);
  const [updateCompleted, setUpdateCompleted] = useState(false);
  const [partnerPreference, setPartnerPreference] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [ReligionList, SetReligionList] = useState([]);
  const [CommunityList, setCommunityList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [motherTongueList, setMotherTongueList] = useState([]);
  const [ageRange, setAgeRange] = useState([21, 36]);
  const [heightRange, setHeightRange] = useState([4.5, 7.1]);

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [religion, setReligion] = useState([]);
  const [community, setCommunity] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [motherTongue, setMotherToungue] = useState([]);

  const token = localStorage.getItem("authToken");

  const loadState = async (country) => {
    try {
      const stateData = await fetchStateMultiple(country);
      setStateList(stateData);
    } catch (err) {
      console.error("Error loading state:", err);
    }
  };

  const loadCity = async (country, state) => {
    try {
      const cityData = await fetchCityMultiple(country, state);
      setCityList(cityData);
    } catch (err) {
      console.error("Error loading city:", err);
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    const coun = typeof value === "string" ? value.split(",") : value;
    const _countryNames = [];
    coun.map((co) => {
      _countryNames.push({ label: co, value: co });
    });

    setState([]);
    setCity([]);
    loadState(_countryNames);
    loadCity([], []);
    setCountry(coun);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    const stateV = typeof value === "string" ? value.split(",") : value;
    const _stateNames = [];
    stateV.map((st) => {
      _stateNames.push({ label: st, value: st });
    });
    const _countryNames = [];
    country.map((co) => {
      _countryNames.push({ label: co, value: co });
    });
    setCity([]);
    loadCity(_countryNames, _stateNames);
    setState(stateV);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    const cityV = typeof value === "string" ? value.split(",") : value;
    setCity(cityV);
  };

  const handlechangeReligion = (event) => {
    const { value } = event.target;
    const religionV = typeof value === "string" ? value.split(",") : value;
    setReligion(religionV);
  };

  const handlechangeCommunity = (event) => {
    const { value } = event.target;
    const religionV = typeof value === "string" ? value.split(",") : value;
    setCommunity(religionV);
  };

  const handleChangeMaritalStatus = (event) => {
    const { value } = event.target;
    const maritalStatusV = typeof value === "string" ? value.split(",") : value;
    setMaritalStatus(maritalStatusV);
  };

  const handleChangeMotherTongue = (event) => {
    const { value } = event.target;
    const motherTongueV = typeof value === "string" ? value.split(",") : value;
    setMotherToungue(motherTongueV);
  };

  let navigate = useNavigate();
  if (!token) {
    console.log("No token found. Redirecting to login.");
    navigate("/");
  }
  useEffect(() => {
    if (!token) {
      console.log("No token found. Redirecting to login.");
      navigate("/");
    } else {
      
      const loadDetails = async () => {
        try {
          const whereObj = { token };
          const userData = await fetchData("*", "users", whereObj);
          setUserDetails(userData[0]);
        } catch (error) {
          console.error("Error loading user details:", error);
        }
      };
      loadDetails();

      const loadCountry = async () => {
        try {
          const countryData = await fetchCountry();
          setCountryList(countryData);
        } catch (err) {
          console.error("Error loading country:", err);
        }
      };

      loadCountry();

      const loadReligion = async () => {
        try {
          const religionData = await fetchReligion();
          SetReligionList(religionData);
        } catch (error) {
          console.error("Error loading religion:", error);
        }
      };

      loadReligion();

      const loadCommunity = async () => {
        try {
          const communityData = await fetchCommunity();
          setCommunityList(communityData);
        } catch (error) {
          console.error("Error loading community:", error);
        }
      };
      loadCommunity();

      const loadMarital = async () => {
        try {
          const maritalData = await fetchMaritalStatus();
          setMaritalStatusList(maritalData);
        } catch (error) {
          console.error("Error loading marital:", error);
        }
      };

      loadMarital();

      const loadmotherTongue = async () => {
        try {
          const motherTongueData = await fetchMotherTongue();
          setMotherTongueList(motherTongueData);
        } catch (error) {
          console.error("Error loading mother tongue:", error);
        }
      };

      loadmotherTongue();
    }
  }, [token, userEdit, navigate]);

  useEffect(() => {
    const loadPreferences = async () => {
      try {
        if (userDetails.uniquecode) {
          const whereObj = { user_id: userDetails.uniquecode };
          const pref = await fetchData("*", "partner_preferences", whereObj);
          if (pref) {
            const preference = pref[0];
            setPartnerPreference(preference);
            setAgeRange([preference.start_age, preference.end_age]);
            setHeightRange([
              convertHeight(preference.start_height_lable),
              convertHeight(preference.end_height_lable),
            ]);

            if (preference.religion) {
              const relegionSet = preference.religion
                .split(",")
                .map((rel) => rel);
              setReligion(relegionSet);
            }

            if (preference.community) {
              const comSet = preference.community
                .split(",")
                .map((com) => com);
              setCommunity(comSet);
            }

            if (preference.mother_tongue) {
              const motherSet = preference.mother_tongue
                .split(",")
                .map((mo) => mo);
              setMotherToungue(motherSet);
            }

            if (preference.marital_status) {
              const mariStatus = preference.marital_status
                .split(",")
                .map((moa) => moa);
              setMaritalStatus(mariStatus);
            }

            const c = [];
            if (preference.country) {
              const countryD = preference.country
                .split(",")
                .map((countryS) => countryS);

              setCountry(countryD);

             
              preference.country
                .split(",").map((co) => {
                  c.push({ label: co, value: co });
              });

              loadState(c);
            
            }

            if (preference.state) {
              const stateD = preference.state
                .split(",")
                .map((stateS) => stateS);
              setState(stateD);

              const cf = [];
              preference.state
                .split(",").map((ss) => {
                  cf.push({ label: ss, value: ss });
              });

              loadCity(c,cf);
            }

            if (preference.city) {
              const cityD = preference.city
                .split(",")
                .map((citys) => citys);
              setCity(cityD);
            }
          }
        }
      } catch (error) {
        console.error("Error loading preferences:", error);
      }
    };
    loadPreferences();
  }, [userDetails.uniquecode,updateCompleted]);


  const convertHeightDetails = (height) => {
   
    const [feet, inches] = height.toString().split('.').map(Number);
    const returnV = {
      feet: feet,
      inches: inches || 0, 
      formattedHeight: `${feet}' ${inches || 0}"`,
      totalInches: feet * 12 + (inches || 0)
    };
    return returnV;
  };

  const updatePartnerEdit = async (event) => {
    setUpdateCompleted(false);
    event.preventDefault();
    document.getElementById("setting_update").style.display = "none";
    document.getElementById("settingLoad").style.display = "unset";
    const formData = new FormData();
    formData.append("user_id", partnerPreference.user_id);
    formData.append("start_age", ageRange[0]);
    formData.append("end_age",ageRange[1]);

    const startHeight = convertHeightDetails(heightRange[0]);
    const endHeight = convertHeightDetails(heightRange[1]);

    formData.append("start_height_lable",startHeight.formattedHeight);
    formData.append("end_height_lable", endHeight.formattedHeight);

    formData.append("start_height_inch",startHeight.totalInches);
    formData.append("end_height_inch", endHeight.totalInches);

    if (religion) {
      formData.append("religion", religion);
    }
    if (motherTongue) {
      formData.append("mother_tongue",motherTongue);
    }

    if (maritalStatus) {
      formData.append("marital_status",maritalStatus);
    }

    if (country) {
      formData.append("country",country);
    }


    if (state) {
      formData.append("state", state);
    }

    if (city) {
      formData.append("city", city);
    }

    if (community) {
      formData.append("community",community)
     
    };

    
    try {
      const response = await fetch(`${baseUrl}/updatePartnerPref`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        setTimeout(function () {
          document.getElementById("settingLoad").style.display = "none";
          document.getElementById("setting_update").style.display = "unset";
          toastr.success("Preferences Updated Successfully.");
          setUpdateCompleted(true);
        }, 1500);

      } else {
        toastr.error("Failed to update preferences. Please try again.");
        document.getElementById("settingLoad").style.display = "none";
        document.getElementById("setting_update").style.display = "unset";
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
      console.error("Error updating preferences:", error);
      document.getElementById("settingLoad").style.display = "none";
      document.getElementById("setting_update").style.display = "unset";
    }

  }

  return (
    <section className="setting w70 m_auto">
      <h1>Setting</h1>

      <div className="setting_bx fcc-display setting_contact">
        <h3>Contact Details:</h3>
        <h6>
          <i className="bi bi-envelope-fill"></i> Email ID:{" "}
          <a href={userDetails.email}>{userDetails.email}</a>
          <sup>
            <i
              className="bi bi-check-circle-fill"
              style={{ color: "yellowgreen" }}
            ></i>{" "}
            {userDetails.email_verified ? "Verified" : ""}
          </sup>
        </h6>
        <h6>
          <i className="bi bi-phone-fill"></i> Phone Number: {userDetails.phone}
          <sup>
            <i
              className="bi bi-check-circle-fill"
              style={{ color: "yellowgreen" }}
            ></i>{" "}
            {userDetails.phone_verified ? "Verified" : ""}
          </sup>
        </h6>
      </div>
      <form action="" method="post" onSubmit={updatePartnerEdit}>
      <div className="setting_bx setting_filter">
        <h3>Contact Filters:</h3>
        <div className="sub_filters">
          <h5>Who can contact me?</h5>
          <p>
            Only Members matching the below criteria will get to see your
            contact details
          </p>

          <div className="contact_card">
            <div className="contact_head contact_head_ah">
              <label>
                <i className="bi bi-calendar-week-fill"></i> Age
              </label>
              <h5>
                Select a minimum age range of 3 years to get relevant matches.
              </h5>
              <p>Selected Age range</p>
              <div className="count">
                <span>{ageRange[0]}</span>
                to
                <span>{ageRange[1]}</span>
              </div>
              <Box sx={{ m: 3 }}>
                <AirbnbSlider
                  value={ageRange}
                  max={45}
                  min={21}
                  onChange={(e, v) => setAgeRange(v)}
                  ThumbComponent={AirbnbThumbComponent}
                  getAriaLabel={(index) =>
                    index === 0 ? "Minimum age" : "Maximum age"
                  }
                />
              </Box>
            </div>
          </div>

          <div className="contact_card">
            <div className="contact_head contact_head_ah">
              <label>
                <i className="bi bi-arrows-vertical"></i> Height
              </label>
              <h5>
                {" "}
                Select a minimum height range of 6 inches to get relevant
                matches
              </h5>
              <p>Selected Height range</p>
              <div className="count">
                <span>{heightRange[0]}</span>
                to
                <span>{heightRange[1]}</span>
              </div>
              <Box sx={{ m: 3 }}>
                <AirbnbSlider
                  value={heightRange}
                  max={7}
                  min={4}
                  onChange={(e, v) => setHeightRange(v)}
                  ThumbComponent={AirbnbThumbComponent}
                  getAriaLabel={(index) =>
                    index === 0 ? "Minimum height" : "Maximum height"
                  }
                />
              </Box>
            </div>
          </div>

          <div className="contact_card fcs-display select_card_filter">
            {/* Country  */}
            <div className="contact_head">
              <label>
                <i className="bi bi-globe-asia-australia"></i> Country
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={country}
                  onChange={handleChangeCountry}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {CountryList.map((countries) => (
                    <MenuItem key={countries.country} value={countries.country}>
                      <Checkbox
                        checked={country.indexOf(countries.country) > -1}
                      />
                      <ListItemText primary={countries.country} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* State  */}
            <div className="contact_head">
              <label>
                <i className="bi bi-globe-asia-australia"></i> State
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">State</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={state}
                  onChange={handleChangeState}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {StateList.map((stat) => (
                    <MenuItem key={stat.state} value={stat.state}>
                      <Checkbox checked={state.indexOf(stat.state) > -1} />
                      <ListItemText primary={stat.state} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* City  */}
            <div className="contact_head">
              <label>
                <i className="bi bi-globe-asia-australia"></i> City
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">City</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={city}
                  onChange={handleChangeCity}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {CityList.map((cit) => (
                    <MenuItem key={cit.city} value={cit.city}>
                      <Checkbox checked={city.indexOf(cit.city) > -1} />
                      <ListItemText primary={cit.city} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Religion */}
            <div className="contact_head">
              <label>
                <i className="bi bi-book-fill"></i> Religion
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Religion
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={religion}
                  onChange={handlechangeReligion}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {ReligionList.map((reli) => (
                    <MenuItem key={reli.name} value={reli.name}>
                      <Checkbox checked={religion.indexOf(reli.name) > -1} />
                      <ListItemText primary={reli.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Community */}

            <div className="contact_head">
              <label>
                <i className="bi  bi-people-fill"></i> Spritiual path
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Spritiual path
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={community}
                  onChange={handlechangeCommunity}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {CommunityList.map((name) => (
                    <MenuItem key={name.name} value={name.name}>
                      <Checkbox checked={community.indexOf(name.name) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Mother tongue */}
            <div className="contact_head">
              <label>
                <i className="bi bi-globe"></i> Mother tongue
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Mother tongue
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={motherTongue}
                  onChange={handleChangeMotherTongue}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {motherTongueList.map((name) => (
                    <MenuItem key={name.name} value={name.name}>
                      <Checkbox
                        checked={motherTongue.indexOf(name.name) > -1}
                      />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Marital Status */}
            <div className="contact_head">
              <label>
                <i className="bi bi-calendar-week-fill"></i> Marital Status
              </label>
              <br />
              <FormControl sx={{ m: 1, mt: 3, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Marital Status
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={maritalStatus}
                  onChange={handleChangeMaritalStatus}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {maritalStatusList.map((name) => (
                    <MenuItem key={name.name} value={name}>
                      <Checkbox
                        checked={maritalStatus.indexOf(name.name) > -1}
                      />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div id="settingUpdateDiv">
      <button type="submit" id="setting_update">
                Update <i className="bi bi-save"></i>
              </button>
              <button type="button" id="settingLoad" disabled>
                Updating.. <i className="bi bi-save"></i>
              </button>
      </div>
      </div>

    
     
      </form>
      <div className="setting_bx fcc-display setting_contact">
        <h3>Hide / Delete Account</h3>
        <div className="hide_delete">
          <label>
            Hide Account <i className="bi bi-eye-fill"></i>
          </label>
          {/* <label>Delete Account <i className="bi bi-trash-fill"></i></label> */}
        </div>
      </div>
    </section>
  );
};

export default Setting;
