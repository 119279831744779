import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../home/index.jsx";
import Dashboard_d from '../dashboard/dashboard_dash.jsx';
import My_photo from "../dashboard/my_photo.jsx";
import Partner_preference from "../dashboard/partner_preference.jsx";
import Setting from "../dashboard/setting.jsx";
import My_profile from "../dashboard/my_profiles.jsx";
import NewMatches from "../matches/new_matches.jsx";
import MyMatches from "../matches/my_matches.jsx";
import TodaysMatches from "../matches/todays_matches.jsx";
import NearMe from "../matches/nearme.jsx";
import Shortlisted from "../matches/shortlistes.jsx";
import BasicSearch from "../search/basic_search.jsx";
import AdvancedSearch from "../search/advanced_search.jsx";
import Received from "../inbox/received.jsx";
import Accepted from "../inbox/accepted.jsx";
import Sent from "../inbox/sent.jsx";
import Cancelled from "../inbox/cancelled.jsx";
import User from "../user_details/user.jsx";
import Contactus from "../contact/contact_us.jsx";
import Plan from "../plan/index.jsx";



let RouteSet = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/dashboard' element={<Dashboard_d />} />
            <Route path='/myphoto' element={<My_photo />} />
            <Route path='/partner_preference' element={<Partner_preference />} />
            <Route path='/setting' element={<Setting />} />
            <Route path='/myprofile' element={<My_profile />} />
            <Route path='/newmatches' element={<NewMatches />} />
            <Route path='/mymatches' element={<MyMatches />} />
            <Route path='/todaysmatches' element={<TodaysMatches />} />
            <Route path='/nearme' element={<NearMe />} />
            <Route path='/shortlisted' element={<Shortlisted />} />
            <Route path='/basic_search' element={<BasicSearch />} />
            <Route path='/advanced_search' element={<AdvancedSearch />} />
            <Route path='/received' element={<Received />} />
            <Route path='/accepted' element={<Accepted />} />
            <Route path='/sent' element={<Sent />} />
            <Route path='/cancelled' element={<Cancelled />} />
            <Route path='/user/:uniquecode' element={<User />} />
            <Route path='/contactus' element={<Contactus />} />
            <Route path='/plan' element={<Plan />} />
            
        </Routes>
    )
}


export default RouteSet;