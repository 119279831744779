import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const nav = document.getElementsByTagName('nav')[0];
    const logoImg = nav.getElementsByTagName('img')[0];
    nav.classList.add('active_nav');
    
    const handleScroll = () => {
      if (window.scrollY > 5) {
        if (location.pathname === '/') {
          logoImg.src = 'img/logo_wh.png';
          nav.classList.add('active_nav');
          nav.classList.add('active_nav1');
        } else {
          nav.classList.add('active_nav');
          logoImg.src = 'img/logo_wh.png';
        }
        
      } else {
        nav.classList.remove('active_nav');
        nav.classList.remove('active_nav1');
        if (location.pathname === '/') {
          logoImg.src = 'img/logo.png';
        } else {
          nav.classList.add('active_nav');
          logoImg.src = 'img/logo_wh.png';
        }
      }
    };

    handleScroll(); // Initial check on component mount

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (location.pathname === '/') {
        nav.classList.remove('active_nav');
        logoImg.src = 'img/logo.png';
      } else {
        nav.classList.add('active_nav');
        logoImg.src = 'img/logo_wh.png';
      }
    };
  }, [location.pathname]);

  return (
    <footer className="w100">
      <div className="w75 sub_footer fcs-display m_auto">
        <div className="foot_bx">
          <img src="/img/logo_wh.png" alt="Company Logo" />
          <p className="fw5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam aut molestias, laudantium animi quasi deserunt.
          </p>
          <p className="fw5">
          284, Bharat Appartment, Sector-16B, Dwarka, New Delhi-110078
          </p>
        </div>
        <div className="foot_bx">
          <h5>Need Help?</h5>
          <ul>
            <li><Link to="#">Member Login</Link></li>
            <li><Link to="#">Sign Up</Link></li>
            <li><Link to="#">Partner Search</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Company</h5>
          <ul>
            <li><Link to="#">About Us</Link></li>
            <li><Link to="/plan">Plan</Link></li>
            <li><Link to="/nri">VIP/NRI Shadi</Link></li>
            <li><Link to="#">Success Stories</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Privacy & You</h5>
          <ul>
            <li><Link to="#">Privacy Policy</Link></li>
            <li><Link to="#">Terms Of Use</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Follow Us</h5>
          <div className="social_media">
            <Link to="#"><i className="bi bi-facebook"></i></Link>
            <Link to="#"><i className="bi bi-youtube"></i></Link>
            <Link to="#"><i className="bi bi-instagram"></i></Link>
            <Link to="#"><i className="bi bi-twitter"></i></Link>
          </div>
          <ul>
            <li><a href="tel:0123456789"><i className="bi bi-phone-fill"></i> +91 8860020628</a></li>
            <li><a href="mailto:satsangirishtey@gmail.com"><i className="bi bi-envelope-fill"></i> satsangirishtey@gmail.com</a></li>
          </ul>
        </div>
      </div>
      <h6 className="m_auto center w75">Copyright 2023-2024 by satsangirishtey.com. All Rights Reserved.</h6>
    </footer>
  );
};

export default Footer;
