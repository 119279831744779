import React, { useState } from 'react';
import { Link } from "react-router-dom";

let Index = () =>
{

   let [ Looking_for, SetLooking_for ] = useState( 'woman' );
   let [ Start_age, SetStart_age ] = useState( '18' );
   let [ End_age, SetEnd_age ] = useState( '27' );
   let [ login_btn, Setlogin_btn ] = useState( 0 );

   const communityes = [
      "Brahma Kumaris",
      "Divya Jyoti Jagrati Sansthan",
      "Dera Sacha Sauda, Sirsa",
      "Guruji",
      "Jagat Guru Rampal Ji",
      "Manav Utthan Sewa Samiti",
      "Radha Saomi Satsang, Beas",
      "Radhasoami Satsang, Dayalbagh",
      "Radha Swami Satsang Dinod",
      "Sachkhand Nanak Dham, Loni",
      "Sadhguru",
      "Sant Asharamji Bapuji",
      "Sant Nirankari",
      "Sri Sri Ravi Shankar",
      "Sawan Kirpal Ruhani Mission",
      "Other"
   ];

   let [ community, SetCommunity ] = useState( communityes[ 0 ] );


   const handleChange = ( e, input ) =>
   {
      const value = e.target.value;


      if ( /^\d*$/.test( value ) )
      {
         const age = parseInt( value, 10 );

         if ( input == 'start' )
         {
            SetStart_age( value );
         } else
         {
            SetEnd_age( value )
         }
      }
   };


   function handelFoucs ( id )
   {
      document.getElementById( id ).classList.add( 'drop_bx_active' );
   }

   function handelFoucsout ( id )
   {
      let el = document.getElementById( id );
      if ( el )
      {
         setTimeout( () =>
         {
            document.getElementById( id ).classList.remove( 'drop_bx_active' );
         }, 300 );
      }
   }




   


   return (
      <>
         <header className='fccf-display'>
            <div className="content fccf-display">
               <h1>Satsangirishtey.com</h1>
               <p>India's First Matrimonial Website for Satsangi Rishtey</p>
            </div>

            <div className="search_bx fcs-display">
               <div className='filter_card'>
                  <label htmlFor="Looking For a"><img src="./assets/icon/couple.png" alt="" /> I'am looking for a</label>
                  <br />
                  <input type="text" placeholder="Woman" value={ Looking_for } onFocus={ () => handelFoucs( 'looking_for_drop_bx' ) } onBlur={ () => handelFoucsout( 'looking_for_drop_bx' ) }  onChange={()=>{}}/>
                  <i className="bi bi-caret-down-fill"></i>
                  <div className='drop_bx' id='looking_for_drop_bx'>
                     <li onClick={ () => SetLooking_for( 'women' ) }>Women</li>
                     <li onClick={ () => SetLooking_for( 'man' ) }>Man</li>
                  </div>
               </div>
               <div className='filter_card'>
                  <label htmlFor="Looking For a"><img src="./assets/icon/couple.png" alt="" /> Age</label>
                  <br />
                  <div className="inputs fcs-display">
                     <input type="text" placeholder="Age" style={ { marginRight: '10px' } } onChange={ ( e ) => handleChange( e, 'start' ) } value={ Start_age } /> <span style={ { margin: '0 5px', position: 'relative', left: '-5px', top: '5px', color: '#fff' } }>to</span>
                     <input type="text" placeholder="Age" onChange={ ( e ) => handleChange( e, 'end' ) } value={ End_age } />
                  </div>
               </div>
               <div className='filter_card'>
                  <label htmlFor="Looking For a"><img src="./assets/icon/couple.png" alt="" /> Follower of</label>
                  <br />
                  <input type="text" placeholder="Select" value={ community } onFocus={ () => handelFoucs( 'follower_of_drop_bx1' ) } onBlur={ () => handelFoucsout( 'follower_of_drop_bx1' ) } onChange={()=>{}}/>
                  <i className="bi bi-caret-down-fill"></i>
                  <div className='drop_bx' id='follower_of_drop_bx1'>
                     {
                        communityes.map( ( el, i ) => (
                           <li key={ i } onClick={ () => SetCommunity( el ) }>{ el }</li>
                        ) )
                     }
                  </div>
               </div>
               <div className='filter_card' style={ { width: 'auto' } }>
                  <button className='btn_reset'>Let's Search</button>
               </div>
            </div>
         </header>


         <div className="w100 m_auto  nri_tags nri_tags_first">
            <h4 className='center'>BROWSE</h4>
            <h1 className='head center'><span>Matrimonial</span> Profiles By</h1>
            <ul className="w100 fcs-display"><li className="fw5">Brahma Kumaris</li><h5></h5><li className="fw5">Divya Jyoti Jagrati Sansthan</li><h5></h5><li className="fw5">Dera Sacha Sauda, Sirsa</li><h5></h5><li className="fw5">Guruji</li><h5></h5><li className="fw5">Jagat Guru Rampal Ji</li><h5></h5><li className="fw5">Manav Utthan Sewa Samiti</li><br /><li className="fw5">Radha Saomi Satsang, Beas</li><h5></h5><li className="fw5">Radhasoami Satsang, Dayalbagh</li><h5></h5><li className="fw5">Radha Swami Satsang Dinod</li><h5></h5><li className="fw5">Sachkhand Nanak Dham, Loni</li><h5></h5><li className="fw5">Sadhguru</li><br /><li className="fw5">Sant Asharamji Bapuji</li><h5></h5><li className="fw5">Sant Nirankari</li><h5></h5><li className="fw5">Sri Sri Ravi Shankar</li><h5></h5><li className="fw5">Sawan Kirpal Ruhani Mission</li><h5></h5><li className="fw5">Other</li></ul>
         </div>


         <section className="spiritual w100  fcc-display">
            <div className="sub_spiritual_bx w70 m_auto">
            <div className="right_bx">
               <h4>Which spiritual path do you follow?</h4>
               <h4>Are you looking for a life partner following the same path?</h4>
               <h4>Then search your life partner on our website</h4>
               <h4>Create your profile and search your satsangi life partner</h4>
            </div>
            <div className="left_bx">
               {/* <img src="img/spirtual couple.jpg" alt="" /> */}
            </div>
            </div>
         </section>


         <div className="register_band w80 fcs-display">
            <h1 className='head'>Your Satsangi Life Partner is Waiting for you</h1>
            <button className='btn_reset'>Register Now</button>
         </div>


         <section className='why w60 m_auto'>
            <h1 className='head center'>Why Satsangirishtey.com?</h1>

            <div className="why_cards fcs-display">

               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_1.png" alt="" />
                  </div>
                  <p className='center fw6'>Only satsangi profiles on our website.</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_2.png" alt="" />
                  </div>
                  <p className='center fw6'>Profile database of more than 15+ spiritual paths.</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_3.png" alt="" />
                  </div>
                  <p className='center fw6'>Safe & Secure website with verified profiles.</p>
               </div>

            </div>

         </section>


         <div className="register_band register_band2 w80 ">
            <h1 className='head'>Your Spiritual Journey can become very easy</h1>
            <h1 className='head'>with a life partner following the same path</h1>
         </div>








         <section className='why signup_process w70 m_auto'>
            <h1 className='head center'>Find Your <span>Satsangi</span> Life Partner</h1>

            <div className="why_cards fcs-display">

               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_4.png" alt="" />
                  </div>
                  <h5>Sign Up</h5>
                  <p className='center fw6'>Register for free & create your profile</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_5.png" alt="" style={ { width: '170px' } } />
                  </div>
                  <h5>Connect</h5>
                  <p className='center fw6'>Select and connect with Satsangi profile you like</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_6.png" alt="" />
                  </div>
                  <h5>Interact</h5>
                  <p className='center fw6'>Become a premimum member and start a conversation</p>
               </div>

            </div>

         </section>



         <section className='why signup_process  three_step w70 m_auto'>
            <h4 className='center' style={ { color: 'gray' } }>THREE SIMPLE STEP TO</h4>
            <h1 className='head center' style={ { marginTop: '10px' } }>Find the <span>One for you</span></h1>

            <div className="why_cards fcs-display">

               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_7.png" alt="" />
                  </div>
                  {/* <h5>Sign Up</h5> */ }
                  <p className='center fw6'><span>01.</span> Define your partner preferences</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_8.png" alt="" style={ { width: '150px' } } />
                  </div>
                  {/* <h5>Connect</h5> */ }
                  <p className='center fw6'><span>02.</span> Browse Profiles</p>
               </div>


               <div className="why_card fccf-display">
                  <div className="img_bx">
                     <img src="icon/illu_9.png" alt="" />
                  </div>
                  {/* <h5>Interact</h5> */ }
                  <p className='center fw6'><span>03.</span> Send Interests & Connect</p>
               </div>

            </div>

         </section>



         <section className="nri_rishtey w50 fcs-display">
            <div className="left_bx_ad">
               <h1 className='center'>Exclusive & Personalized <br /> Rishtey for You</h1>
            </div>
            <div className="right_bx_ad fcsf-display">
               <h1>NRI Rishtey <br /> VIP Rishtey</h1>
               <button className='btn_reset'>Get Started</button>
            </div>
            <div className="tags fcc-display w100">
               <p>Personal Counsellor</p>
               <p>Rishtey from All Across the world</p>
               <p>Higher Success Rate</p>
               <p>100% Privacy</p>
            </div>
         </section>




         <div className="w70 m_auto fcc-display nri_tags">
            <ul className='w100 fcs-display'>
               <li className='fw5'>India</li>
               <h5></h5>
               <li className='fw5'>USA</li>
               <h5></h5>
               <li className='fw5'>Canada</li>
               <h5></h5>
               <li className='fw5'>UK</li>
               <h5></h5>
               <li className='fw5'>Signapore</li>
               <h5></h5>
               <li className='fw5'>Australia</li>
               <h5></h5>
               <li className='fw5'>UAE</li>
               <h5></h5>
               <li className='fw5'><Link to='#'>NRI Matrimonials <i className="bi bi-caret-right-fill"></i></Link></li>
            </ul>
         </div>


      </>
   )
}

export default Index;